const mimeTypes = {
  tex: 'application/x-tex',
  gd: 'text/plain',
  key: 'application/pkcs8',
  vob: 'application/octet-stream',
  rar: 'application/x-rar-compressed, application/octet-stream',
};

const exceptionTypes = {
  avi: 'video/avi',
  mov: 'video/quicktime',
  wmv: 'video/x-ms-wmv',
  heif: 'image/heif',
  heic: 'image/heic',
};

const getMimeType = (file) => {
  if (!file) return '';
  const splittedName = file.name.split('.');
  const ext = splittedName[splittedName.length - 1];
  const excType = exceptionTypes[ext];

  if (!file.type && excType) {
    return excType;
  }
  if (file.name.includes('.rtf') && file.type === 'application/msword') {
    return file.type.replace('msword', 'rtf');
  }
  const extention = file.fileType;
  const mimeType = file.type || file.mime || mimeTypes[extention] || '';
  return mimeType;
};

export default getMimeType;
